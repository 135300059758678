import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~8],
		"/(agent)/agent_beta": [~10,[2]],
		"/(agent)/agent": [~9,[2]],
		"/(app)/autocall/audio": [~11,[3]],
		"/(app)/autocall/template": [~12,[3]],
		"/(app)/autocall/template/new": [~14,[3]],
		"/(app)/autocall/template/[id]": [~13,[3]],
		"/(app)/autocall/tts-request": [~16,[3]],
		"/(app)/autocall/tts": [15,[3]],
		"/(app)/autocall/wizard": [~17,[3]],
		"/(app)/callback": [~18,[3]],
		"/(app)/campaign": [~19,[3]],
		"/(app)/campaign/[id]": [~20,[3]],
		"/(app)/carrier": [~21,[3]],
		"/(app)/cdr": [~22,[3]],
		"/(app)/contact": [~23,[3]],
		"/(app)/contact/[id]": [~24,[3]],
		"/(app)/contract": [~25,[3]],
		"/(app)/contract/campaign": [~27,[3]],
		"/(app)/contract/campaign/[id]": [~28,[3]],
		"/(app)/contract/[id]": [~26,[3]],
		"/(app)/dashboard": [~29,[3]],
		"/(app)/dnc": [30,[3]],
		"/(app)/export": [~31,[3]],
		"/(app)/extension": [~32,[3]],
		"/(app)/history": [~33,[3]],
		"/(app)/lead": [~34,[3]],
		"/(app)/lead/[id]": [~35,[3]],
		"/(app)/license": [~36,[3]],
		"/(app)/list": [~37,[3]],
		"/(app)/list/custom/[id]": [~38,[3]],
		"/(auth)/login": [~67],
		"/logout": [~72],
		"/(app)/monitor": [~39,[3]],
		"/(app)/monitor/autocall": [~40,[3]],
		"/(app)/monitor/callcenter": [~41,[3]],
		"/(app)/monitor/manage_extension": [~42,[3]],
		"/(partner)/partner": [~71,[7]],
		"/(app)/qc": [~43,[3]],
		"/(app)/recording": [~44,[3]],
		"/(app)/report": [~45,[3]],
		"/(app)/setting": [~46,[3,4]],
		"/(app)/setting/blacklist": [~47,[3,4]],
		"/(app)/setting/department": [~48,[3,4]],
		"/(app)/setting/group_hotline": [~50,[3,4]],
		"/(app)/setting/group": [~49,[3,4]],
		"/(app)/setting/hotline_autodialer": [~51,[3,4]],
		"/(app)/setting/incom_report": [~52,[3,4]],
		"/(app)/setting/mail_template": [~53,[3,4]],
		"/(app)/setting/mail_template/[id]": [~54,[3,4]],
		"/(app)/setting/new_mail_template": [~55,[3,4]],
		"/(app)/setting/new_script": [~56,[3,4]],
		"/(app)/setting/role": [~57,[3,4]],
		"/(app)/setting/script": [~58,[3,4]],
		"/(app)/setting/script/[id]": [~59,[3,4]],
		"/(app)/setting/speech_to_text": [~60,[3,4]],
		"/(app)/setting/webhook": [61,[3,4]],
		"/(app)/setting/zns": [~62,[3,4]],
		"/(config)/setup": [~68,[5,6]],
		"/(config)/setup/domain": [69,[5,6]],
		"/(config)/setup/domain/[id]": [~70,[5,6]],
		"/(app)/speech_to_text": [~63,[3]],
		"/(app)/speech_to_text/[id]": [~64,[3]],
		"/(app)/user": [~65,[3]],
		"/(app)/voicemail": [~66,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';